import { Nullable } from '@/core/domain/type/types'

export class TextureDTO
{
    id?: Nullable<string>;
    description?: Nullable<string>;
    type?: Nullable<string>;
    category?: Nullable<string>;

    constructor({ id, description, type, category } : { id?: Nullable<string>, description?: Nullable<string>, type?: Nullable<string>, category?: Nullable<string>}) {
        this.id = id;
        this.description = description;
        this.type = type;
        this.category = category;
    }
}

